import React, { useContext } from "react";
import Buscar from "./components/botonera/Buscar";
import "../Listado/botonera.scss";
import Cancelar from "./components/botonera/Cancelar";
import SwitchColumnas from "./components/SwitchColumnas";
import ListadoSubmitBoton from "./components/botonera/OrBoton.js";

const Botonera = ({
  handleSubmit,
  handleCancelar,
  loading,
  buscar,
  cancelar,
  acciones,
  className,
  context,
  submit,
  submit_handleSubmit,
  submit_texto,
  data,
  origen,
  hayDatos,
}) => {
  const ctx = useContext(context);

  if (!submit && !buscar && !cancelar && acciones?.length === 0) return <></>;
  //console.log(ctx.opciones)
  //console.log("consultaEjecutada " + ctx.opciones.id_a + " " + ctx.opciones.consultaEjecutada);
  return (
    <div className={`${className ?? "container_buttons_l"}`}>
      {buscar ? (
        <Buscar handleSubmit={handleSubmit} loading={loading} />
      ) : (
        <></>
      )}
      {cancelar ? <Cancelar handleCancelar={handleCancelar} /> : <></>}
      {hayDatos &&
      submit &&
      ctx.opciones.consultaEjecutada === "s" &&
      !loading &&
      data &&
      data?.length !== 0 ? (
        <ListadoSubmitBoton
          text={submit_texto ?? "Seleccionar"}
          color="white"
          bg="#3065ac"
          handleSubmit={submit_handleSubmit}
          context={context}
        />
      ) : (
        <></>
      )}

      {loading
        ? []
        : acciones
            ?.sort((a, b) => a.orden - b.orden)
            .map((accion) => {
              
              if (hayDatos || accion.mostrar_sin_resultados === "s") {
                return (
                  <div
                    className="container_switch_acciones"
                    style={{
                      gridColumn:
                        accion.grid_span && accion.grid_span !== ""
                          ? accion.grid_span
                          : "span 2",
                      margin: "6px",
                      display: "flex",
                    }}
                    key={JSON.stringify(accion)}
                  >
                    <SwitchColumnas
                      cab={accion}
                      data={data}
                      Context={context}
                    />
                  </div>
                );
              }
              return <></>;
            })}
    </div>
  );
};

export default Botonera;
