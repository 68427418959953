export const searchData = (manager) => {
  manager.grouped = manager.treefied = manager.sorted = manager.paged = false;

  manager.searchedData = [...manager.filteredData];

  if (manager.searchText && manager.applySearch) {
    const trimmedSearchText = manager.searchText.trim();
    manager.searchedData = manager.searchedData.filter((row) => {
      return manager.columns
        .filter((columnDef) => {
          return columnDef.searchable === undefined
            ? !columnDef.hidden
            : columnDef.searchable;
        })
        .some((columnDef) => {
          // console.log(columnDef.customFilterAndSearch)
          if (columnDef.customFilterAndSearch) {
            return !!columnDef.customFilterAndSearch(
              trimmedSearchText,
              row,
              columnDef
            );
          } else if (columnDef.field) {
            const value = manager.getFieldValue(row, columnDef);

            if (columnDef.children && columnDef.children.length > 0) {
              return columnDef.children.some((c) => {
                const innerValue = row[c.id_a];
                let found = false;
                if (innerValue) {
                  found = innerValue
                    .toString()
                    .toUpperCase()
                    .includes(trimmedSearchText.toUpperCase());
                }
                return found;
              });
            }
            if (value) {
              return value
                .toString()
                .toUpperCase()
                .includes(trimmedSearchText.toUpperCase());
            }
          }
        });
    });
  }
  manager.searched = true;
  return;
};

export function calcularOpcionesPageSize(pageSize, cantdd_registros_max = 30) {
  if (typeof cantdd_registros_max === "string") {
    cantdd_registros_max = parseInt(cantdd_registros_max);
  }

  let opciones = [pageSize, 5, 10, 20];

  if (!isNaN(cantdd_registros_max)) {
    opciones.push(cantdd_registros_max);
  } else {
    opciones.push(30);
  }

  opciones = opciones.filter(
    (valor, indice) => opciones.indexOf(valor) === indice
  );
  // opciones = opciones.filter((v) => v <= pageSize);
  opciones = opciones.sort((a, b) => a - b).filter((f) => f);
  return opciones;
}

export function updateSelectedArray(selected, row) {
  row = row.filter((obj) => {
    const k = Object.keys(obj).filter((k) =>
      k.endsWith("listado_seleccion_habilitado")
    )[0];
    if (!k) return true;
    return obj[k] === 1;
  });

  if (!selected) {
    return row;
  }
  let newSelected = [...selected];

  const isTodosPresentes = row.every((itemToCheck) =>
    selected.some(
      (referenceItem) => referenceItem.tableData.id === itemToCheck.tableData.id
    )
  );

  if (isTodosPresentes) {
    row.forEach((rowItem) => {
      newSelected = newSelected.filter(
        (ns) => ns.tableData.id !== rowItem.tableData.id
      );
    });

    return newSelected;
  }

  row.forEach((rowItem) => {
    const index = newSelected.findIndex(
      (selectedItem) => selectedItem.tableData.id === rowItem.tableData.id
    );

    if (index === -1) {
      newSelected.push(rowItem);
    } else {
      // newSelected.splice(index, 1);
    }
  });

  return newSelected;
}
