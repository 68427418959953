import React from "react";
import { Map, Marker, GoogleApiWrapper, InfoWindow } from "google-maps-react";
import { google_api } from "../../../../../config";

import icon from "../../../../../assets/images/Logo-farmageo-borde.png";

export function Mapa(props) {
  const { data, cab, hijos, campokey, indiceData, id_elemento } = props;

  const lat = data[cab.id_a + "_mapa_latitud"] ?? cab.mapa_latitud;
  const long = data[cab.id_a + "_mapa_longitud"] ?? cab.mapa_longitud;
  const zoom = cab.mapa_zoom ? Number(cab.mapa_zoom) : lat && long ? 15 : 3;

  return (
    <div className="map_container" style={{ height: cab.height || "200px" }}>
      <Map
        containerStyle={{ width: "100%" }}
        google={props.google}
        className="map"
        zoom={zoom}
        // centerAroundCurrentLocation={true}
        initialCenter={{
          lat: lat ?? -38.416097,
          lng: long ?? -63.616672,
        }}
        center={{
          lat: lat,
          lng: long,
        }}
        style={{ flex: 1, display: "flex", width: "100%" }}
        // onClick={onMapClicked}
      >
        <Marker
          position={{
            lat: lat,
            lng: long,
          }}
          icon={{
            url: icon,
            anchor: new props.google.maps.Point(25, 25),
            scaledSize: new props.google.maps.Size(55, 55),
          }}
          onClick={() => {
            window.open(
              `https://www.google.com/maps/dir/?api=1&destination=${lat},${long}`
            );
          }}
        />
        <InfoWindow marker={true} />
      </Map>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: google_api,
})(Mapa);
