import React, { createContext } from "react";
import ReactDOM from "react-dom";
import { v4 } from "uuid";
import "animate.css";
// ES6 Modules or TypeScript
// CommonJS
import withReactContent from "sweetalert2-react-content";
const S = require("sweetalert2");


export const ALERT = ({
  title,
  text,
  icon,
  confirmButtonText,
  timer = 5000,
  denyButtonText,
  html
}) => {
  return withReactContent(S).fire({
    title: title,
    text: text,
    icon: icon,
    confirmButtonText: confirmButtonText && confirmButtonText,
    timer: timer,
    denyButtonText: denyButtonText && denyButtonText,
    showDenyButton: denyButtonText && true,
    allowOutsideClick: true,
    allowEscapeKey: true,
    html
  });
};

export const alertaToastGuardar = (cab, valor, data) => {
  const Toast = S.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = S.stopTimer;
      toast.onmouseleave = S.resumeTimer;
    },
  });
  Toast.fire({
    icon: "info",
    title: `${cab.label} guardando`,
  });
};

const Toast = ({ message, type }) => {
  const estilo = (() => {
    switch (type) {
      case "confirmado":
        return {
          style: {
            backgroundColor: "whitesmoke",
            padding: "0.5rem",
            borderRadius: "15px",
            border: "3px solid green",
            color: "black",
            fontSize: "0.8rem",
          },
          classes:
            "animate__animated animate__headShake toast_mensaje_confirmado toast_div_confirmado",
        };
      case "error":
        return {
          style: {
            backgroundColor: "whitesmoke",
            padding: "0.5rem",
            borderRadius: "15px",
            border: "3px solid red",
            color: "black",
            fontSize: "0.8rem",
          },
          classes:
            "animate__animated animate__headShake toast_mensaje_error toast_div_error",
        };
      case "procesando":
        return {
          style: {
            backgroundColor: "whitesmoke",
            padding: "0.5rem",
            borderRadius: "15px",
            color: "black",
            fontSize: "0.8rem",
            border: "1px solid lightgray",
          },
          classes:
            "animate__animated animate__slideInRight animate__faster  toast_mensaje_guardando toast_div_guardando",
        };
      default:
        console.log("toast switch");
    }
  })();

  return (
    <div style={estilo.style} className={`${estilo.classes}`}>
      {message}
    </div>
  );
};

const AlertasContext = createContext();
export const AlertasProvider = (props) => {
  const registro = {};

  const addContainer = () => {
    // Creamos un div nuevo que actuará como contenedor en el DOM
    const notificationContainer = document.createElement("div");

    // Establecer atributos
    notificationContainer.id = "otoaster";
    notificationContainer.className =
      "swal2-top-end swal2-backdrop-show body swal2-toast-shown";
    notificationContainer.style.overflowY = "auto";
    notificationContainer.style.overflowX = "hidden";
    notificationContainer.style.pointerEvents = "none";
    notificationContainer.style.maxWidth = "460px";
    notificationContainer.style.right = "0px";
    notificationContainer.style.top = "20px";
    notificationContainer.style.position = "fixed";
    notificationContainer.style.zIndex = "3001";

    // Utilizamos ReactDOM.render() para renderizar el componente React dentro del nuevo contenedor DOM
    // ReactDOM.render(notificationContainer);

    // Agregamos el contenedor al cuerpo del documento
    document.body.appendChild(notificationContainer);
  };

  const addNotificacion = (props) => {
    const { id, message, type, timer } = props;
    let notificationContainer = document.getElementById("otoaster");
    if (!notificationContainer) {
      notificationContainer = addContainer();
    }

    try {
      const notificationElement = document.createElement("div");
      notificationElement.style.pointerEvents = "auto";
      notificationElement.style.paddingTop = "0.3rem";
      notificationElement.id = id;
      notificationContainer.appendChild(notificationElement);
      ReactDOM.render(
        <Toast message={message} type={type} />,
        notificationElement
      );
      if (timer !== 0) {
        setTimeout(() => {
          removeToastAlert({ id, notificationElement }); // timeout
        }, timer ?? 3000);
      }
    } catch (err) {
      addNotificacion({ ...props });
    }

    return;
  };

  const removeNotificacion = (toastId) => {
    const toastElement = document.getElementById(toastId);
    if (toastElement) {
      toastElement.remove();
      delete registro[toastId];
    }
  };

  const modToastAlert = ({ id, message, type, timer }) => {
    const toast = { ...registro[id] };
    toast.show = () => showToastAlert({ id, message, type, timer });
    toast.remove();
    toast.show();
    registro[id] = toast;
    return registro[id];
  };

  const addToastAlert = ({ message, type }) => {
    const id = v4();
    registro[id] = {
      id: id,
      remove: () => removeNotificacion(id),
      show: () => showToastAlert({ id, message, type }),
      toSuccess: ({ message, timer }) =>
        modToastAlert({ id, message, type: "confirmado", timer }),
      toError: ({ message, timer }) =>
        modToastAlert({ id, message, type: "error", timer }),
    };
    return registro[id];
  };

  const showToastAlert = ({ id, message, type, timer }) => {
    switch (type) {
      case "procesando":
        addNotificacion({
          id,
          message,
          type: "procesando",
          timer,
        });
        break;
      case "confirmado":
        addNotificacion({
          id,
          message,
          type: "confirmado",
          timer,
        });
        break;
      case "error":
        addNotificacion({
          id,
          message: message?.toString(),
          type: "error",
          timer,
        });
        break;
      default:
        console.log("Alerta desconocida");
    }
  };

  const removeToastAlert = ({ id, notificationElement }) => {
    if (registro[id]) {
      notificationElement.classList.add(
        "animate__animated",
        "animate__fadeOut"
      );
      setTimeout(() => {
        if (registro[id]) {
          registro[id].remove();
        }
      }, 1000);
    }
  };

  return (
    <AlertasContext.Provider value={{ ALERT, addToastAlert }}>
      {props.children}
    </AlertasContext.Provider>
  );
};
export default AlertasContext;
