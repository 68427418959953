import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

function alertarSqlError(error) {
  const { culpable, timer, sqlMessage, errorSql } = error;
  const sql = errorSql.replace(/\n/g);
  return MySwal.fire({
    title: error.code ?? "Error",
    icon: "error",
    timer: timer ?? 10000,
    confirmButtonText: "Aceptar",
    html: (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
            fontSize: "0.9rem",
          }}
        >
          <span style={{ fontWeight: "bold" }}>sqlMessage:</span>
          <span
            style={{
              textAlign: "justify",
              flex: "1",
              marginLeft: "10px",
              fontSize: "0.8rem",
            }}
          >
            {sqlMessage}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            fontSize: "0.9rem",
          }}
        >
          <span style={{ fontWeight: "bold" }}>Configuracion:</span>
          {culpable.map(({ id, atributo, id_a }) => (
            <a
              key={id}
              href={`/#/Vista/PANTALLA_VISTA_CONFIGURACION?id=${id}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                color: "#007bff",
                marginBottom: "5px",
                transition: "color 0.3s",
              }}
              onMouseEnter={(e) => (e.target.style.color = "#339af0")}
              onMouseLeave={(e) => (e.target.style.color = "#007bff")} // Opcional: estilos de enlace
            >
              <p style={{ margin: 0 }}>
                {id_a}: ver {atributo}
              </p>
            </a>
          ))}
        </div>
        <details style={{ fontSize: "0.8rem", textAlign: "left" }}>
          <summary style={{ cursor: "pointer" }}>Mostrar SQL</summary>
          <div>{sql}</div>
        </details>
      </div>
    ),
    showCloseButton: true,
    showConfirmButton: false,
  });
}

export { alertarSqlError };
