import React from "react";
import SwitchCampos from "../Vista/components/SwitchCampos";
import HeaderConf from "./HeaderConf";
import { Card, CardBody } from "reactstrap";

function VistaEstatica({ data, cab, indiceData, id_elemento }) {
  const styles = {
    gridColumn: cab.grid_span ? cab.grid_span : "1 / -1",
    border: "none",
    marginBottom: 0,
  };
  return (
    <>
      <Card style={styles} id={cab.id_a + "_modal"}>
        <HeaderConf
          opciones={cab}
          className="configuracion_pantalla_titulo_secundario"
        />
        <CardBody style={{ padding: 0 }}>
          {cab.sc_hijos.map((c) => {
            return (
              <SwitchCampos
                key={c.id_a}
                data={data}
                cab={c}
                indiceData={indiceData}
                id_elemento={id_elemento}
              />
            );
          })}
        </CardBody>
      </Card>
    </>
  );
}

export default VistaEstatica;
