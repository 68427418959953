import React, {
  useContext,
  useEffect,
  useRef,
  useCallback,
  useState,
  useMemo,
} from "react";
import MaterialTable, { MTableBody } from "@material-table/core";
import ListadoContext from "../context/ListadoContext";
import PantallaContext from "../../context/PantallaContext";
import { agregarAcciones } from "../../helper/funciones";

import {
  calcularOpcionesPageSize,
  updateSelectedArray,
  searchData,
} from "./ListadoFunciones";
// import Carrusel from "./Carrusel";

import { Card, CardBody } from "reactstrap";
import HeaderConf from "../../components/HeaderConf";

import tableIcons from "./tableIcons";
import Filtros from "./Filtros";
import Botonera from "../Botonera";

import { TablePagination } from "@material-ui/core";

import SwitchColumnas from "./SwitchColumnas";
import MemoizedOrixiaRow from "./MemoizedOrixaRow";
import Mapa from "./Mapa";
import OrixiaSpinner from "../../components/OrixiaSpinner";

import "./listado.scss";

export const Listado = (props) => {
  const { columnas, tots } = props;

  const {
    opcionesListado,
    listadoBotones,
    Dispatch,
    cabeceras,
    datos,
    subirDatosSeleccionados,
    loading,
    cambiarPagina,
    handleCancelar,
  } = useContext(ListadoContext);

  const calcPagesize = useCallback(() => {
    let size = 10;

    if (opcionesListado.cantdd_registros) {
      size =
        Number(opcionesListado.cantdd_registros) === 0
          ? datos.length
          : Number(opcionesListado.cantdd_registros);
    }

    if (datos.length === 0) {
      size = 0;
    }
    if (datos.length < size) {
      size = datos.length;
    }
    if (
      opcionesListado.cantdd_registros_max &&
      size > opcionesListado.cantdd_registros_max
    ) {
      size = opcionesListado.cantdd_registros_max;
    }
    if (
      opcionesListado.paginar_backend === "s" &&
      opcionesListado.paginacion_backend
    ) {
      return opcionesListado.paginacion_backend.perPage;
    }
    return Number(size);
  }, [
    datos.length,
    opcionesListado.cantdd_registros,
    opcionesListado.cantdd_registros_max,
    opcionesListado.paginacion_backend,
    opcionesListado.paginar_backend,
  ]);

  const { opciones_de_pantalla } = useContext(PantallaContext);

  const [selected, setSelected] = useState();
  const [pageOptions, setPageOptions] = useState([]);
  const [mapa, setMapa] = useState(false);
  const [currentPageSize, setCurrentPageSize] = useState(
    opcionesListado.cantdd_registros ?? 0
  );

  const renderSummaryRow =
    cabeceras.filter((c) => c.totalizar === "s").length > 0;

  const listadoBotonesInferiores = listadoBotones.filter(
    (b) => b.listado_boton_inferior === "s"
  );

  const padding = "2px 6px";

  const pageSize = calcPagesize();
  const tableRef = useRef(null);

  useEffect(() => {
    tableRef.current.dataManager.changePageSize(calcPagesize());
    setCurrentPageSize(calcPagesize());
    setPageOptions(() =>
      calcularOpcionesPageSize(pageSize, opcionesListado.cantdd_registros_max)
    );
    tableRef.current.dataManager.searchData = () =>
      searchData(tableRef.current.dataManager);
  }, [calcPagesize, columnas, opcionesListado.cantdd_registros_max, pageSize]);

  const styles = {
    gridColumn: opcionesListado.grid_span
      ? opcionesListado.grid_span
      : "1 / -1",
  };

  const mensajeSinDatos = (
    <p style={{ paddingTop: "15px" }}>
      {opcionesListado.consultaEjecutada === "n"
        ? ""
        : "No se encontraron datos"}
    </p>
  );

  agregarAcciones(opciones_de_pantalla, opcionesListado);

  const mapaCab = columnas?.filter((c) => c.componente === "mapa").pop();

  let labelDisplayedRows = "{from}-{to} de {count}";

  if (opcionesListado.paginacion_backend) {
    const { currentPage, total, perPage, exactTotal } =
      opcionesListado.paginacion_backend;
    const desde = perPage * (currentPage - 1) + 1;
    const hasta = Math.min(currentPage * perPage, total);

    labelDisplayedRows = exactTotal
      ? `${desde}-${hasta} de ${total}`
      : `${desde}-${hasta} de aprox. ${total}`;
  }
  return (
    <div
      style={styles}
      className={`animated fadeIn novedades_lista ${opcionesListado.className}`}
    >
      <Card
        id={opcionesListado.id_a}
        style={{ border: "none", marginBottom: 0 }}
        className="listado_card"
      >
        <HeaderConf
          opciones={opcionesListado}
          className={"configuracion_pantalla_titulo_secundario"}
        />

        <CardBody style={{ padding: 0 }} className="listado_card_body">
          <Filtros opciones={opcionesListado} setMapa={setMapa} data={datos} />
          {loading || opcionesListado === undefined ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "80%",
                position: "fixed",
                zIndex: 500,
              }}
            >
              <OrixiaSpinner text={"Cargando..."} />
            </div>
          ) : (
            <></>
          )}
          <MaterialTable
            tableRef={tableRef}
            isLoading={loading || opcionesListado === undefined}
            hideSortIcon={false}
            style={{ display: mapa ? "none" : "inherit" }}
            icons={tableIcons}
            renderSummaryRow={
              renderSummaryRow
                ? (j) => {
                    const { column } = j;
                    if (column.totalizar === "s") {
                      return {
                        value: tots[column.field].toFixed(2),
                        style: {
                          textAlign: column.cellStyle.textAlign ?? "right",
                          borderTop: "double",
                          fontSize: "0.8rem",
                          padding: "2px 6px",
                        },
                      };
                    }
                    return undefined;
                  }
                : undefined
            }
            localization={{
              header: {
                //actions: "Acciones",
              },
              body: {
                emptyDataSourceMessage: mensajeSinDatos,
              },
              pagination: {
                labelDisplayedRows: labelDisplayedRows,
                labelRowsSelect: "Filas",
                labelRowsPerPage: "Productos x pág",
                firstAriaLabel: "Primera",
                lastAriaLabel: "Ultima",
                firstTooltip: "Primera página",
                lastTooltip: "Ultima página",
                previousAriaLabel: "Página anterior",
                previousTooltip: "Página anterior",
                nextAriaLabel: "Próxima pagina",
                nextTooltip: "Próxima pagina",
              },
              toolbar: {
                searchTooltip: "Buscar",
                searchPlaceholder: "Buscar",
              },
            }}
            columns={useMemo(
              opcionesListado.consultaEjecutada === "s"
                ? () =>
                    cabeceras
                      .sort((a, b) => a.orden - b.orden)
                      .filter((cab) => cab.componente !== "hidden")
                      .filter((cab) => cab.componente !== "null")
                      .filter((cab) => cab.componente !== "mapa")
                      .filter((cab) => cab.mostrar !== "n")
                      .map((cab, i) => {
                        // console.log("cab:  ", cab);

                        return {
                          title: cab.nombre_alias
                            ? datos[0][cab.nombre_alias]
                            : cab.nombre,
                          field: cab.campo_alias ?? cab.id_a,
                          width: undefined,
                          totalizar: cab.totalizar ?? undefined,
                          // customFilterAndSearch: (term, rowData, column) =>console.log("custom filter and search",term,rowData,column),
                          cellStyle: {
                            textAlign: cab.align ?? "center",
                            // width: cab.width ?? "10%",
                            padding: padding,
                            fontSize: "0.8rem",
                            lineHeight: 1,
                            width: undefined,
                          },
                          id: cab.id_a,
                          headerStyle: {
                            textAlign: "left",
                            fontSize: "0.8rem",
                            padding: padding,
                            height: "0.8em",
                            width: undefined,
                          },
                          children: cab.sc_hijos,
                          render: (data) => {
                            const totalStyle = {
                              ...cab,
                              borderTop: "double",
                            };
                            const id_elemento =
                              cab.id_a + "_" + data.tableData.id;
                            return (
                              <SwitchColumnas
                                data={data}
                                cab={data.TOTALES === 1 ? totalStyle : cab}
                                indiceData={data.tableData.id}
                                // Context={ListadoContext}
                                id_elemento={id_elemento}
                                key={data._key}
                              />
                            );
                          },
                        };
                      })
                : () => [],
              [cabeceras, datos]
            )}
            data={datos}
            actions={[]}
            options={{
              filtering: false,
              actionsColumnIndex: -1,
              showTitle: false,
              toolbar:
                opcionesListado.search === "s" && datos.length !== 0 && datos,
              pageSize: currentPageSize,
              pageSizeOptions: pageOptions,
              headerStyle: {
                textAlign: "left",
                fontWeight: "bold",
                height: "57px",
              },
              search: opcionesListado.search === "s",
              searchFieldStyle: { top: "3px" },
              // tableLayout: "fixed",
              selection:
                datos.length > 0 &&
                opcionesListado.listado_seleccion &&
                opcionesListado.listado_seleccion === "s",
              showSelectAllCheckbox:
                datos.length > 0 &&
                opcionesListado.showSelectAllCheckbox &&
                opcionesListado.showSelectAllCheckbox === "s",
              showTextRowsSelected: opcionesListado.showTextRowsSelected,
              rowStyle: (r) => {
                if (opcionesListado.rowStyle) {
                  return r.tableData.id === selected
                    ? {
                        background: "#bde0b1",
                        color: "black",
                        fontWeight: 600,
                      }
                    : {};
                }
                return {};
              },
            }}
            onSelectionChange={(row, l) => {
              if (!l && row.length !== 0) {
                row = row.filter((r) => {
                  const c = tableRef.current.dataManager.pagedData.some(
                    (pd) => pd.tableData.id === r.tableData.id
                  );
                  return c;
                });
                row = updateSelectedArray(selected, row);
              }
              setSelected(row);

              Dispatch({
                type: "SET_DATOS_SELECCIONADOS",
                payload: {
                  row,
                  data: datos.map((d, i) => {
                    d.tableData = { checked: false, index: i };
                    row.forEach((r) => {
                      if (r.tableData.index === i) {
                        d.tableData = r.tableData;
                      }
                    });

                    return d;
                  }),
                },
              });

              if (subirDatosSeleccionados) {
                // console.log("vivo por aqui")
                subirDatosSeleccionados(row, opcionesListado);
              }

              // funcion select
              if (opcionesListado.listado_seleccion_habilitado) {
                row = row.filter(
                  (r) =>
                    r[
                      opcionesListado.id_a + "_listado_seleccion_habilitado"
                    ] === 1
                );
              }

              if (!!opcionesListado.onSelectionChange) {
                opcionesListado.onSelectionChange(row);
              }
              return row;
            }}
            onRowClick={(e, row) => {
              if (opcionesListado.onRowClick) {
                setSelected(row.tableData.id);
                opcionesListado.onRowClick(e, row, datos);

                Dispatch({
                  type: "SET_DATOS_SELECCIONADOS",
                  payload: {
                    row,
                    data: datos.map((d, i) => {
                      d.tableData = { checked: false, index: i };
                      [row].forEach((r) => {
                        if (r.tableData.index === i) {
                          d.tableData = r.tableData;
                        }
                      });

                      return d;
                    }),
                  },
                });
              }
            }}
            onRowDoubleClick={(e, row) => {
              if (opcionesListado.onRowDoubleClick) {
                setSelected(row.tableData.id);
                opcionesListado.onRowDoubleClick(e, row, datos);
              }
            }}
            components={{
              OverlayLoading: () => {
                return (
                  <div
                    style={{
                      display: "table",
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgb(255, 255, 255)",
                      opacity: "0.7",
                    }}
                  >
                    <div
                      style={{
                        display: "table-cell",
                        width: "100%",
                        height: "100%",
                        verticalAlign: "middle",
                        textAlign: "center",
                      }}
                    ></div>
                  </div>
                );
              },
              Body: (props) => {
                if (opcionesListado.consultaEjecutada === "n") return <></>;
                return <MTableBody {...props} />;
              },
              Row: (props) => {
                const habilitado = opcionesListado.listado_seleccion_habilitado
                  ? props.data[
                      opcionesListado.id_a + `_listado_seleccion_habilitado`
                    ] === 1
                  : true;

                return (
                  // <MTableBodyRow id={props.data._id} {...props} enableRowSelection={false}                    />
                  // <OrixiaRow {...props} enableRowSelection={habilitado} />
                  <MemoizedOrixiaRow
                    {...props}
                    enableRowSelection={habilitado}
                  />
                );
              },
              Pagination: (props) => {
                //  if (data.length === 0 || !data) return <></>;
                if (opcionesListado.paginacion === "n") return <></>;
                if (opcionesListado.consultaEjecutada === "n") return <></>;

                if (opcionesListado.paginar_backend === "s") {
                  const { paginacion_backend } = opcionesListado;

                  return (
                    <TablePagination
                      {...props}
                      className={
                        opcionesListado.paginacion === "n" ? "d-none" : ""
                      }
                      page={Number(paginacion_backend.currentPage)}
                      rowsPerPage={currentPageSize}
                      count={
                        paginacion_backend.hasTotal
                          ? Number(paginacion_backend.total) +
                            Number(paginacion_backend.perPage)
                          : -1
                      }
                      onPageChange={(ob, page) => {
                        cambiarPagina(page, currentPageSize);
                      }}
                      onRowsPerPageChange={(e, b) => {
                        setCurrentPageSize(e.target.value);
                        if (e.target.value > paginacion_backend.perPage) {
                          cambiarPagina(
                            paginacion_backend.currentPage,
                            e.target.value
                          );
                        }

                        return e.target.value;
                      }}
                    />
                  );
                }

                return (
                  <TablePagination
                    className={
                      opcionesListado.paginacion === "n" ? "d-none" : ""
                    }
                    {...props}
                    labelRowsPerPage={() => <></>}
                  />
                );
              },
            }}
            // style={{ minWidth: "1300px", overflowX: "auto" }}
          />

          {datos.length > 0 && opcionesListado.usar_mapa === "s" ? (
            <Mapa
              display={mapa ? "inherit" : "none"}
              data={datos}
              cab={mapaCab}
            />
          ) : (
            <></>
          )}
          <Botonera
            handleSubmit={() => null}
            handleCancelar={handleCancelar}
            loading={loading || opcionesListado.consultaEjecutada !== "s"}
            buscar={0}
            cancelar={0}
            acciones={listadoBotonesInferiores}
            className={"container_buttons_inferiores"}
            context={ListadoContext}
            submit={opcionesListado.submit_button}
            submit_handleSubmit={opcionesListado.submit_button_handleSubmit}
            submit_texto={opcionesListado.submit_texto}
            data={datos[0]}
            hayDatos={datos.length > 0}
          />
        </CardBody>
      </Card>
      {/* </Col>
      </Row> */}
    </div>
  );
};

export default Listado;
