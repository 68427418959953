import React, { useState, useContext } from "react";
import Select from "./Select";
import FuncionesContext from "../context/FuncionesContext";
import { useEffect } from "react";
import Obligatorio from "../ABM/componentes/inputs/ObligatorioA";
import { useRefDataStore } from "../../..";

const Select_Enlazable = ({
  data,
  cab,
  hijos,
  campokey,
  indiceData,
  context,
  id_elemento,
}) => {
  const { superSubmit, refrescarConfiguracion } = useContext(FuncionesContext);

  const { dispatch, setFiltro, filtros } = useContext(context);

  const [value, setValue] = useState(() => {
    if (filtros[cab.id_a]) {
      return filtros[cab.id_a];
    }
    return data[campokey];
  });

  const [lastValue, setLastvalue] = useState(data[campokey]);

  const [v, setStore] = useRefDataStore((s) => s[data._key + campokey]);

  const nombre = (() => {
    if (cab.nombre_alias) {
      return data[cab.nombre_alias];
    }
    return cab.nombre;
  })();

  const handleCancelar = () => {
    setValue(data[campokey]);
    setLastvalue(data[campokey]);
  };

  const handleChange = async (e) => {
    let valor = e.target.value;
    const update_id = data[cab.update_id_alias];

    if (valor === null || lastValue?.toString() === valor.toString().trim())
      return;

    const { id_a } = cab;

    if (typeof cab.opciones[0].value === "number") {
      valor = parseInt(valor, 10);
    }

    setValue(valor);

    if (cab.select_es_maestro === "s") {
      setFiltro({ id_a, valor });
      refrescarConfiguracion({ cab });

      // console.log(cab.id_a, "filtros:", filtros);

      return;
    }

    superSubmit({ valor, id_a, update_id, handleCancelar, cab, data })
      .then((result) => {
        setLastvalue(valor);
        // dispatch({
        //   type: "SET_DATO_ESPECIFICO",
        //   payload: {
        //     key: cab.update_id_alias, // update_id
        //     indiceData: indiceData, // 4
        //     value: result.data.registro.id,
        //   },
        // });
        // dispatch({
        //   type: "SET_DATO_ESPECIFICO",
        //   payload: {
        //     key: cab.id_a, // update_id
        //     indiceData: indiceData, // 4
        //     value: valor,
        //   },
        // });
        setStore({ [data._key + campokey]: valor });

        setStore({
          [data._key + campokey + "_label"]: opciones
            .filter((op) => op.value === valor)
            .pop().label,
        });
      })
      .catch((err) => {
        console.log("Cancelado ", err);
      });
  };



  const disabled = (() => {
    if (data[cab.id_a + "_disabled"]) {
      return data[cab.id_a + "_disabled"] === "s";
    }
    if (cab.disabled) {
      return cab.disabled === "s";
    }
    return false;
  })();

  const { permite_null } = cab;

  const componerOpciones = () => {
    const value_null =
      data[cab.id_a + "_select_value_null"] ?? cab.select_value_null;
    const label_null =
      data[cab.id_a + "_select_label_null"] ?? cab.select_label_null;
    const opcion_null = {
      value: value_null ? value_null : null,
      label: label_null ? label_null : "Ninguno",
      disabled: disabled === "true",
    };
    if (Array.isArray(cab.opciones)) return cab.opciones;
    if (data[cab.id_a + "_opciones"]) {
      const opciones_de_query_general = JSON.parse(
        data[cab.id_a + "_opciones"]
      );

      if (Array.isArray(opciones_de_query_general)) {
        if (value_null || label_null)
          opciones_de_query_general.push(opcion_null);
        return opciones_de_query_general;
      }
    }
    if (value_null || label_null) {
      return [opcion_null];
    }
    return [];
  };

  const opciones = componerOpciones();

  useEffect(() => {
    //setea informacion para recuperarla como dataView
    setStore({
      [data._key + campokey + "_label"]: opciones
        .filter((op) => op.value?.toString() === value?.toString())
        .pop()?.label,
    });

  },[campokey, data._key, opciones, setStore, value]);

  return (
    //<div style={{ textAlign: "center"}}>
    <div id={id_elemento} style={{ flex: "1 0 100%" }}>
      <div
        className="vista_label_select"
        //style={{ fontWeight: "bold", display: "flex", width: "100%" }}
      >
        {cab.nombre ? (
          <p style={{ flexShrink: 0, flexWrap: "wrap" }}>{cab.nombre}:</p>
        ) : (
          <></>
        )}
        <Select
          id={cab.id_a}
          nombre={nombre}
          opciones={opciones}
          value={value}
          onChange={handleChange}
          def={cab.default}
          disabled={disabled}
          className={data[cab.id_a + "_className"] ?? cab.className}
          permite_null={permite_null}
        />
        <Obligatorio permite_null={cab.permite_null} />
        {hijos}
      </div>
    </div>
  );
};

export default Select_Enlazable;
