import React, { useState, useContext, useEffect, useRef } from "react";
import FuncionesContext from "../../../context/FuncionesContext";
import TextArea from "../../../components/TextArea";
// import ListadoContext from "../../context/ListadoContext";
import { TextField } from "@mui/material";
import { useRefDataStore } from "../../../../..";

const InputAreaEditable = ({ data, cab, campokey, type, id_elemento }) => {
  const { superSubmit, validarRegex } = useContext(FuncionesContext);

  // const { ListadoDispatch } = useContext(ListadoContext);

  const [v, setStore] = useRefDataStore((s) => s[data._key + campokey]);
  const [update_id] = useRefDataStore(
    (s) => s[data._key + cab.update_id_alias]
  );

  const [value, setValue] = useState(v);
  const [lastValue, setLastvalue] = useState(v);
  const [updateId, setUpdateId] = useState(update_id);

  let classNames = data[cab.id_a + "_className"] ?? cab.className;

  const disabled = (() => {
    if (data[cab.id_a + "_disabled"]) {
      return data[cab.id_a + "_disabled"] === "s";
    }
    if (cab.disabled) {
      return cab.disabled === "s";
    }
    return false;
  })();

  const handleCancelar = () => {
    setValue(v);
    const inputElement = document.getElementById(id_elemento);
    inputElement.value = v;
  };

  // Ref para almacenar la referencia al elemento enfocado
  const focusedElementRef = useRef(null);
  if (focusedElementRef.current) {
    //  console.log(focusedElementRef.current);
    focusedElementRef.current.focus();
  }

  const handleGuardar = async (e) => {
    const valor = e.target.value;

    if (valor === lastValue || (valor === "" && lastValue === null)) {
      return;
    }
    if (valor === null || lastValue?.toString() === valor.toString().trim())
      return;

    if (!validarRegex(valor, cab.validacion_regex, cab)) {
      handleCancelar();
      return;
    }

    // const update_id = data[cab.update_id_alias];
    const { id_a } = cab;

    superSubmit({ valor, id_a, update_id: updateId, handleCancelar, cab, data })
      .then((result) => {
        setLastvalue(() => valor);
        setValue(() => valor);

        setStore({ [data._key + campokey]: valor });
        if (!updateId) {
          setUpdateId(result.data.registro.id);
          setStore({
            [data._key + cab.update_id_alias]: result.data.registro.id,
          });
        }
        focusedElementRef.current = document.activeElement;
        return result;
      })
      .catch((err) => {
        console.log("Cancelado ", err);
      });
  };

  const style = (() => {
    if (disabled) {
      return {
        borderColor: "lightgray",
        fontSize: "0.8rem",
        textAlign: cab.align ?? "left",
      };
    }
    if (updateId) {
      return {
        textAlign: cab.align ?? "left",
        borderColor: "darkgreen",
        borderWidth: "2px",
        fontSize: "0.8rem",
      };
    }
    return {
      textAlign: cab.align ?? "left",
      borderColor: "grey",
      fontSize: "0.8rem",
    };
  })();

  let Componente = (
    <TextArea
      value={value ?? ""}
      setValue={setValue}
      onEnter={handleGuardar}
      style={style}
      id={id_elemento}
      disabled={disabled}
      placeholder={cab.placeholder}
    />
  );

  if (type === "number") {
    if (classNames?.includes("native")) {
      return (Componente = (
        <input
          id={id_elemento}
          type="number"
          onBlur={handleGuardar}
          value={value}
          defaultValue={value}
          style={{
            textAlign: "right",
            fontSize: "1em",
            width: cab.width_input,
          }}
          disabled={disabled}
          className={classNames}
        />
      ));
    }

    return (Componente = (
      <TextField
        type="number"
        onBlur={handleGuardar}
        defaultValue={value}
        inputProps={{
          style: {
            textAlign: "right",
            fontSize: "0.8rem",
            width: cab.width_input,
          },
          id: id_elemento,
        }}
        disabled={disabled}
      />
    ));
  }

  return <div className="tarjeta_grid_item_label_item">{Componente}</div>;
};

export default InputAreaEditable;
