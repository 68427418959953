import React, { useRef, useEffect, useState, useContext } from "react";
import { TextareaAutosize } from "@mui/material";
import Label from "../Listado/components/filtros/LabelF";

const TextAreals = ({
  data,
  cab,
  campokey,
  hijos,
  indiceData,
  id_elemento,
  context,
}) => {
  const { addSideData } = useContext(context);

  const [value, setValue] = useState(data[campokey]);
  const [lastValue, setLastvalue] = useState(data[campokey]);

  const label = (() => {
    if (cab.label_alias) {
      return data[cab.label_alias];
    }
    const _label = data[cab.id_a + "_label"] ?? cab.label;
    if (!_label || _label === "") return null;
    return _label;
  })();

  const clave_envio = (() => {
    if (
      data[cab.id_a + "_clave_envio"] &&
      data[cab.id_a + "_clave_envio"] !== ""
    )
      return data[cab.id_a + "_clave_envio"];
    if (cab.clave_envio && cab.clave_envio !== "") return cab.clave_envio;
    return cab.id_a;
  })();

  const handleCancelar = () => {
    setValue(data[campokey]);
    setLastvalue(data[campokey]);
  };

  const handleChange = async (e) => {
    try {
      const valor = e.target.value;

      if (valor === null || lastValue?.toString() === valor.toString().trim())
        return;

      addSideData({ clave_envio: clave_envio, valor });

      // setLastvalue(() => value);
      setValue(() => valor);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const style = (() => {
    if (data[cab.update_id_alias]) {
      return {
        textAlign: cab.align ?? "left",
        fontSize: "0.8rem",
      };
    }
    return {
      textAlign: cab.align ?? "left",
      fontSize: "0.8rem",
      resize: "auto",
    };
  })();
  const keyUp = useRef(0); // Evita que el evento onBlur se dispare luego de onKeyUp
  const altEnter = useRef(0); // Detecta keyDown = alt + enter
  const setingStart = useRef();

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      if (altEnter.current !== 0) return;

      keyUp.current = 1;
      e.target.blur();

      return handleChange(e).then(() => {
        keyUp.current = 0;
      });
    }
    return (altEnter.current = 0);
  };

  const handleAlt = (e) => {
    setingStart.current = e.target.selectionStart;

    if (e.key === "Enter") {
      e.preventDefault();
    }
    if (e.key === "Enter" && e.altKey) {
      altEnter.current = 1;

      setValue(
        value.slice(0, setingStart.current) +
          "\n" +
          value.slice(setingStart.current, value.length)
      );
    }
    return;
  };

  const handleOnBlur = (e) => {
    if (keyUp.current !== 0) return;
    return handleChange(e);
  };

  const handleInput = (e) => {
    const valor = e.target.value;
    setValue(valor);
  };

  useEffect(() => {
    // if (firstRender.current === 0) return (firstRender.current = 1);
    const ctrl = document.getElementById(id_elemento);

    if (ctrl?.setSelectionRange && altEnter.current === 1) {
      ctrl.focus();
      ctrl.setSelectionRange(setingStart.current + 1, setingStart.current + 1);
    }
  }, [value]);

  useEffect(() => {
    return addSideData({ clave_envio: clave_envio, valor: null });
  },[addSideData, clave_envio]);

  return (
    <>
      <Label
        label={label}
        opcionales_null={cab.opcionales_null}
        permite_null={cab.permite_null}
      />
      <TextareaAutosize
        id={id_elemento}
        value={value}
        onChange={handleInput}
        onKeyUp={handleEnter}
        onBlur={handleOnBlur}
        onKeyDown={handleAlt}
        className={cab.className + " tarjeta_grid_item_input_editable"}
        style={style}
        disabled={cab.disabled}
        placeholder={cab.placeholder}
      />
    </>
  );
};

export default TextAreals;
