import React, { useEffect } from "react";
import { Button } from "@mui/material";

import "./botonera.scss";

const Guardar = ({
  handleSubmit,
  loading,
  id,
  texto_confirmar,
  size,
  submitOnEnter = true,
  buttonId = "abm_boton_guardar",
}) => {

  useEffect(() => {
    if (!submitOnEnter) return;
    document.addEventListener("keydown", (e) => {
      if (e.key.toLocaleLowerCase() === "enter") {
        document.getElementById(buttonId).click();
      }
    });
  }, [buttonId, submitOnEnter]);
  return (
    <>
      <Button
        id={buttonId}
        variant="contained"
        color={id ? "primary" : "secondary"}
        size={size ?? "medium"}
        onClick={handleSubmit}
        disabled={loading}
        style={{ width: "100px", marginRight: "20px" }}
        className="btn-guardar"
      >
        {texto_confirmar ?? (id ? "Guardar" : "Crear")}
      </Button>
    </>
  );
};

export default Guardar;
